import './index.css';
import "@fontsource/raleway";
import Research from './pages/Research';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Search from './pages/Search'
import HistoryTrackers from './pages/HistoryTrackers';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route path='/research' element={<Research/>}/>
        <Route path='/abouteprivo' element={<AboutUs/>}/>
        <Route path='/search' element={<Search/>}/>
        <Route path='/historytrackers' element={<HistoryTrackers/>}/>
      </Routes>
    </Router>
  );
}

export default App;
