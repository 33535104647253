import React from "react";
import "../index.css";
import Navbar from "../components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import eprivoLogo from "../assets/eprivo_logo.png";
import "@fontsource/raleway";
import { Col, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Table from "react-bootstrap/Table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { Radar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";

export const optionsRadar = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "center",
    },
  },
};

const labels = [
  "Session cookies",
  "Long living cookies",
  "Very long living cookies",
  "Javascript cookies",
  "Third party cookies",
  "Tracking cookies",
  "Font fingerprinting",
  "Canvas fingerprinting",
  "Canvas fingerprinting big",
  "Mouse fingerprinting",
  "Webgl fingerprinting",
  "Other tracking",
];

//DOMAIN ELEMENTS

const radios = [
  { name: "Most intruders", value: "1" },
  { name: "Most trackers", value: "2" },
  { name: "Most popular", value: "3" },
  { name: "Most third-parties", value: "4" },
];

var intruders = [];
var trackers = [];
var popular = [];
var thirdparty = [];

var session_cookies;
var long_living_cookies;
var very_long_living_cookies;
var javascript_cookies;
var third_party_cookies;
var tracking_cookies;
var font_fingerprinting;
var canvas_fingerprinting;
var canvas_fingerprinting_big;
var mouse_fingerprinting;
var webgl_fingerprinting;
var other_tracking;
var domainName;
var domainElements;
var domainIntrusion;
var domainFP;
var domainTP;

var percentageTracking = 0;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

export var dataDoughnut = {
  labels: ["Using cookies", "Not using cookies"],
  datasets: [
    {
      label: "% of people",
      data: [78, 22],
      backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1,
    },
  ],
};

var dataDoughnut2 = {
  labels: ["Using fingerprinting", "Not using fingerprinting"],
  datasets: [
    {
      label: "% of websites",
      data: [72, 28],
      backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1,
    },
  ],
};

var dataDoughnut3 = {
  labels: ["Using third-party elements", "Not using third-party elements"],
  datasets: [
    {
      label: "% of websites",
      data: [72, 28],
      backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1,
    },
  ],
};

var dataDoughnut4 = {
  labels: ["Using elements", "Not using elements"],
  datasets: [
    {
      label: "% of websites",
      data: [72, 28],
      backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1,
    },
  ],
};

var dataDoughnut5 = {
  labels: ["Tracking", "Not tracking"],
  datasets: [
    {
      label: "% of websites",
      data: [72, 28],
      backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1,
    },
  ],
};

var dataBar1 = {
  labels: [],
  datasets: [
    {
      label: [],
      data: [],
      backgroundColor: ["rgba(255, 99, 132, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)"],
      borderWidth: 1,
    },
  ],
};

var dataBar2 = {
  labels: [],
  datasets: [
    {
      label: [],
      data: [],
      backgroundColor: ["rgba(255, 99, 132, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)"],
      borderWidth: 1,
    },
  ],
};

var dataBar3 = {
  labels: [],
  datasets: [
    {
      label: [],
      data: [],
      backgroundColor: ["rgba(255, 99, 132, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)"],
      borderWidth: 1,
    },
  ],
};

const Home = () => {
  useEffect(() => {
    axios
      .get("https://eprivo.eu/api/api.php?tracking_presence")
      .then((res) => {
        //var total = parseInt(res.data.has_trackers) + parseInt(res.data.offline) + parseInt(res.data.safe);
        var total = parseInt(res.data.has_trackers) + parseInt(res.data.safe);
        percentageTracking = parseInt(res.data.has_trackers) / total;
        percentageTracking = percentageTracking * 100;
        percentageTracking = percentageTracking.toFixed(2);
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get("https://eprivo.eu/api/api.php?intruders")
      .then((res) => {
        intruders = res.data;
        session_cookies = intruders[0][0].session_cookies;
        long_living_cookies = intruders[0][0].long_living_cookies;
        very_long_living_cookies = intruders[0][0].very_long_living_cookies;
        javascript_cookies = intruders[0][0].javascript_cookies;
        third_party_cookies = intruders[0][0].third_party_cookies;
        tracking_cookies = intruders[0][0].tracking_cookies;
        font_fingerprinting = intruders[0][0].font_fingerprinting;
        canvas_fingerprinting = intruders[0][0].canvas_fingerprinting;
        canvas_fingerprinting_big = intruders[0][0].canvas_fingerprinting_big;
        mouse_fingerprinting = intruders[0][0].mouse_fingerprinting;
        webgl_fingerprinting = intruders[0][0].webgl_fingerprinting;
        other_tracking = intruders[0][0].other_tracking;

        domainName = intruders[0].name;
        domainElements = intruders[0][0].elements;
        domainIntrusion = intruders[0].intrusion_level;
        domainFP = intruders[0][0].elements - intruders[0][0].third_parties;
        domainTP = intruders[0][0].third_parties;

        handleNewRadar();
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get("https://eprivo.eu/api/api.php?trackers")
      .then((res) => {
        trackers = res.data;
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get("https://eprivo.eu/api/api.php?popular")
      .then((res) => {
        popular = res.data;
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get("https://eprivo.eu/api/api.php?thirdparty")
      .then((res) => {
        thirdparty = res.data;
      })
      .catch((err) => {
        console.error(err);
      });

    var percentageCookies;
    axios
      .get("https://eprivo.eu/api/api.php?cookies_presence")
      .then((res) => {
        var total =
          parseInt(res.data.has_cookies) + parseInt(res.data.no_cookies);
        percentageCookies = parseInt(res.data.has_cookies) / total;
        percentageCookies = percentageCookies * 100;
        percentageCookies = percentageCookies.toFixed(2);
        dataDoughnut.datasets = [
          {
            label: "% of websites",
            data: [percentageCookies, 100 - percentageCookies],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
            ],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ];
      })
      .catch((err) => {
        console.error(err);
      });

    var percentageFingerprinting;
    axios
      .get("https://eprivo.eu/api/api.php?fingerprinting_presence")
      .then((res) => {
        var total =
          parseInt(res.data.has_fingerprinting) +
          parseInt(res.data.no_fingerprinting);
        percentageFingerprinting =
          parseInt(res.data.has_fingerprinting) / total;
        percentageFingerprinting = percentageFingerprinting * 100;
        percentageFingerprinting = percentageFingerprinting.toFixed(2);
        dataDoughnut2.datasets = [
          {
            label: "% of websites",
            data: [percentageFingerprinting, 100 - percentageFingerprinting],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
            ],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ];
      })
      .catch((err) => {
        console.error(err);
      });

    var numTP;
    var totalTP;
    var percentageTP;
    axios
      .get("https://eprivo.eu/api/api.php?thirdparty_presence")
      .then((res) => {
        numTP = parseInt(res.data.include_third_parties);
        totalTP =
          parseInt(res.data.include_third_parties) +
          parseInt(res.data.no_third_parties);
        percentageTP = numTP / totalTP;
        percentageTP = percentageTP * 100;
        percentageTP = percentageTP.toFixed(2);
        dataDoughnut3.datasets = [
          {
            label: "% of websites",
            data: [percentageTP, 100 - percentageTP],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
            ],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ];
      })
      .catch((err) => {
        console.error(err);
      });

    var percentageHistory;
    axios
      .get("https://eprivo.eu/api/api.php?history_presence")
      .then((res) => {
        var total = parseInt(res.data.tracked) + parseInt(res.data.non_tracked);
        percentageHistory = parseInt(res.data.tracked) / total;
        percentageHistory = percentageHistory * 100;
        percentageHistory = percentageHistory.toFixed(2);
        dataDoughnut4.datasets = [
          {
            label: "% of websites",
            data: [percentageHistory, 100 - percentageHistory],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
            ],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ];
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get("https://eprivo.eu/api/api.php?methods_presence")
      .then((res) => {
        var values = [];
        var methods = [];
        var elem;
        for (var i = 0, len = res.data.length; i < len; i++) {
          elem = res.data[i];
          values.push(parseInt(elem.websites));
          methods.push(elem.method);
        }
        dataBar1.labels = methods;
        dataBar1.datasets = [
          {
            label: ["Instances"],
            data: values,
            backgroundColor: ["rgba(255, 99, 132, 0.2)"],
            borderColor: ["rgba(255, 99, 132, 1)"],
            borderWidth: 1,
          },
        ];
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get("https://eprivo.eu/api/api.php?companies_presence")
      .then((res) => {
        var values = [];
        var companies = [];
        var elem;
        for (var i = 0, len = res.data.length; i < len; i++) {
          elem = res.data[i];
          values.push(parseInt(elem.presency));
          companies.push(elem.company);
        }
        dataBar2.labels = companies;
        dataBar2.datasets = [
          {
            label: ["Websites"],
            data: values,
            backgroundColor: ["rgba(255, 99, 132, 0.2)"],
            borderColor: ["rgba(255, 99, 132, 1)"],
            borderWidth: 1,
          },
        ];
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get("https://eprivo.eu/api/api.php?tracking_presence_top1k")
      .then((res) => {
        //var total = parseInt(res.data.has_trackers) + parseInt(res.data.offline) + parseInt(res.data.safe);
        var total = parseInt(res.data.has_trackers) + parseInt(res.data.safe);
        var trackingTop1k = parseInt(res.data.has_trackers) / total;
        trackingTop1k = trackingTop1k * 100;
        trackingTop1k = trackingTop1k.toFixed(2);
        dataDoughnut5.datasets = [
          {
            label: "% of websites",
            data: [trackingTop1k, 100 - trackingTop1k],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
            ],
            borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
        ];
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get("https://eprivo.eu/api/api.php?google_distribution")
      .then((res) => {
        var values = [];
        var domains = [];
        var elem;
        for (var i = 0, len = res.data.length; i < len; i++) {
          elem = res.data[i];
          values.push(parseInt(elem.presence));
          domains.push(elem.domain);
        }
        dataBar3.labels = domains;
        dataBar3.datasets = [
          {
            label: ["Websites"],
            data: values,
            backgroundColor: ["rgba(255, 99, 132, 0.2)"],
            borderColor: ["rgba(255, 99, 132, 1)"],
            borderWidth: 1,
          },
        ];
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const MyComponent = ({ myObject }) => {
    return (
      <div>
        <Table
          striped
          bordered
          hover
          className="border"
          style={{ tableLayout: "fixed", width: "100%" }}
        >
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th style={{ width: "10%" }}>Rank</th>
              <th>Domain</th>
              <th style={{ width: "15%" }}>Third parties</th>
              <th style={{ width: "15%" }}>Trackers</th>
              <th style={{ width: "15%" }}>Intrusion level</th>
            </tr>
          </thead>
          <tbody className="overflow-scroll overflow-y-scroll">
            {Object.values(myObject).map((array, index) => (
              <TableRow
                key={array.id}
                data={array}
                onClick={() => handleClick(array)}
                index={index}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const [, setActiveRow] = useState(null);

  const handleClick = (array) => {
    setActiveRow(array.id);
    domainName = array.name;
    domainElements = array[0].elements;
    domainIntrusion = array.intrusion_level;
    domainFP = array[0].elements - array[0].third_parties;
    domainTP = array[0].third_parties;

    //Radar chart
    session_cookies = array[0].session_cookies;
    long_living_cookies = array[0].long_living_cookies;
    very_long_living_cookies = array[0].very_long_living_cookies;
    javascript_cookies = array[0].javascript_cookies;
    third_party_cookies = array[0].third_party_cookies;
    tracking_cookies = array[0].tracking_cookies;
    font_fingerprinting = array[0].font_fingerprinting;
    canvas_fingerprinting = array[0].canvas_fingerprinting;
    canvas_fingerprinting_big = array[0].canvas_fingerprinting_big;
    mouse_fingerprinting = array[0].mouse_fingerprinting;
    webgl_fingerprinting = array[0].webgl_fingerprinting;
    other_tracking = array[0].other_tracking;

    handleNewRadar();
  };

  function TableRow({ data, onClick, index }) {
    return (
      <tr onClick={onClick}>
        <td>{index + 1}</td>
        <td>{data.name}</td>
        <td>{data[0].third_parties}</td>
        <td>{data[0].tracking_account}</td>
        <td>{data[0].intrusion_level}</td>
      </tr>
    );
  }

  const [dataRadar, dataRadarNew] = useState({
    labels,
    datasets: [
      {
        label: "# of elements",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });

  function handleNewRadar() {
    dataRadarNew({
      ...dataRadar,
      datasets: [
        {
          label: "# of elements",
          data: [
            session_cookies,
            long_living_cookies,
            very_long_living_cookies,
            javascript_cookies,
            third_party_cookies,
            tracking_cookies,
            font_fingerprinting,
            canvas_fingerprinting,
            canvas_fingerprinting_big,
            mouse_fingerprinting,
            webgl_fingerprinting,
            other_tracking,
          ],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    });
  }

  const [radioValue, setRadioValue] = useState("1");

  function changeTable(value, e) {
    setRadioValue(e.currentTarget.value);
    if (value == 1) {
      document.getElementById("divIntruders").hidden = false;
      document.getElementById("divTrackers").hidden = true;
      document.getElementById("divPopular").hidden = true;
      document.getElementById("divThirdParties").hidden = true;
    } else if (value == 2) {
      document.getElementById("divIntruders").hidden = true;
      document.getElementById("divTrackers").hidden = false;
      document.getElementById("divPopular").hidden = true;
      document.getElementById("divThirdParties").hidden = true;
    } else if (value == 3) {
      document.getElementById("divIntruders").hidden = true;
      document.getElementById("divTrackers").hidden = true;
      document.getElementById("divPopular").hidden = false;
      document.getElementById("divThirdParties").hidden = true;
    } else if (value == 4) {
      document.getElementById("divIntruders").hidden = true;
      document.getElementById("divTrackers").hidden = true;
      document.getElementById("divPopular").hidden = true;
      document.getElementById("divThirdParties").hidden = false;
    }
  }

  const backgroundWhite = {
    backgroundColor: "white",
    height: "auto",
  };

  const styleLogoUPCFIB = {
    width: "10%",
    height: "auto",
    position: "relative",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "5px",
    marginBottom: "15px",
  };

  const navigate = useNavigate();

  function handleSearch() {
    navigate("/search?query=" + query1);
  }

  const [query1, setQuery] = useState();

  const handleChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSearch();
  };

  return (
    <div>
      <Navbar />

      <div
        className="Welcome ePrivo container mx-auto px-6 mt-16 mb-16"
        style={backgroundWhite}
      >
        <h1 style={{ textAlign: "center" }} className="text-5xl">
          <img src={eprivoLogo} style={styleLogoUPCFIB}></img>
          Welcome to ePrivo Observatory
        </h1>
      </div>

      <div className="Description" style={{ backgroundColor: "#F6F6F6" }}>
        <Row className="container mx-auto px-6">
          <Col>
            <div>
              <div className="container mx-auto px-6 mt-16 mb-16">
                <section className="rounded-lg">
                  <div className="rounded-lg">
                    <h2 style={{ textAlign: "center" }} className="font-light">
                      Search a domain to analyze with ePrivo
                    </h2>
                    <section className="mt-4 gap-8 xl:mt-12">
                      <div className="rounded-lg pb-8 pt-1 px-16">
                        <p className="leading-loose text-justify text-gray-500">
                          ePrivo is a tool designed to explore the
                          privacy-firendliness of any given website. ePrivo
                          explores all the internal URLs and resources, looking
                          for web tracking systems running in the background.
                          The detection is based in a state-of-the-art algorithm
                          called TrackSign which is able to heuristically detect
                          web tracking code inside JavaScript and HTML files.
                        </p>
                      </div>
                    </section>
                    <table width={"100%"}>
                      <tbody>
                        <tr>
                          <td align="right">
                            <form onSubmit={handleSubmit}>
                              <input
                                className="border"
                                value={query1}
                                placeholder="Introduce a domain..."
                                onChange={handleChange}
                                width={"1000"}
                                style={{
                                  width: "100%",
                                  maxWidth: "500px",
                                  padding: "10px",
                                  border: "none",
                                  borderRadius: "10px",
                                  marginRight: "10px",
                                  font_size: "18px",
                                }}
                              />
                              <input
                                type="submit"
                                hidden
                                onSubmit={handleSubmit}
                              />
                            </form>
                          </td>
                          <td align="left">
                            <button
                              onClick={handleSearch}
                              style={{
                                backgroundColor: "#007aff",
                                color: "white",
                                padding: "10px 20px",
                                border: "none",
                                borderRadius: "10px",
                                fontSize: "18px",
                                cursor: "pointer",
                              }}
                            >
                              Search
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <section className="bg-white mt-16 mb-8">
        <div className="container mx-auto px-6">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                Why is data privacy important?
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>
          <section className="mt-4 gap-8 xl:mt-12">
            <div className="rounded-lg p-8">
              <p className="leading-loose text-justify text-gray-500">
                Data privacy is crucial to protect individuals' personal
                information and prevent it from being misused. With the
                increasing amount of personal data being collected and shared
                online, it's essential to ensure that this information is kept
                secure and only used for its intended purposes.
              </p>
            </div>
          </section>
          <h3 className="font-normal text-center">
            Percentage of domains using web tracking
          </h3>
          <div className="d-flex justify-center mt-3 lg:mx-52 md:mx-24">
            <Col>
              <ProgressBar
                variant="danger"
                now={percentageTracking}
                label={percentageTracking + "%"}
                defaultValue={0}
              />
            </Col>
          </div>
        </div>
        <section className="container mx-auto px-6 bg-white mt-4 grid grid-cols-1 gap-8 xl:mt-12 rounded-lg lg:mx-20">
          <Carousel interval={5000} variant="dark">
            <Carousel.Item>
              <div className="rounded-lg lg:p-8 h-4/5">
                <div className="d-flex justify-center h-72 mt-4">
                  <Pie data={dataDoughnut} />
                </div>
                <h6 className="font-light text-gray-800 text-center lg:mt-4 lg:gap-8 lg:p-8 ">
                  Websites using cookies
                </h6>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="rounded-lg lg:p-8 h-4/5">
                <div className="d-flex justify-center h-72 mt-4">
                  <Pie data={dataDoughnut2} />
                </div>
                <h6 className="font-light text-gray-800 text-center lg:mt-4 lg:gap-8 lg:p-8 ">
                  Websites using fingerprinting
                </h6>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="rounded-lg lg:p-8 h-4/5">
                <div className="d-flex justify-center h-72 mt-4">
                  <Pie data={dataDoughnut3} />
                </div>
                <h6 className="font-light text-gray-800 text-center lg:mt-4 lg:gap-8 lg:p-8 ">
                  Websites using third-party elements
                </h6>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="rounded-lg lg:p-8 h-4/5">
                <div className="d-flex justify-center h-72 mt-4">
                  <Pie data={dataDoughnut4} />
                </div>
                <h6 className="font-light text-gray-800 text-center lg:mt-4 lg:gap-8 lg:p-8 ">
                  Websites including elements from top 100 browsing history
                  trackers
                </h6>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="rounded-lg lg:p-8 h-4/5">
                <div className="d-flex justify-center h-72 mt-4">
                  <Bar data={dataBar1} />
                </div>
                <h6 className="font-light text-gray-800 text-center lg:mt-4 lg:gap-8 lg:p-8 ">
                  Tracking presence
                </h6>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="rounded-lg lg:p-8 h-4/5">
                <div className="d-flex justify-center h-72 mt-4">
                  <Bar data={dataBar2} />
                </div>
                <h6 className="font-light text-gray-800 text-center lg:mt-4 lg:gap-8 lg:p-8 ">
                  Top third-party companies presence
                </h6>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="rounded-lg lg:p-8 h-4/5">
                <div className="d-flex justify-center h-72 mt-4">
                  <Bar data={dataBar3} />
                </div>
                <h6 className="font-light text-gray-800 text-center lg:mt-4 lg:gap-8 lg:p-8 ">
                  Google domains presence
                </h6>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="rounded-lg lg:p-8 h-4/5">
                <div className="d-flex justify-center h-72 mt-4">
                  <Pie data={dataDoughnut5} />
                </div>
                <h6 className="font-light text-gray-800 text-center lg:mt-4 lg:gap-8 lg:p-8 ">
                  Top 1k most popular websites including tracking
                </h6>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </section>

      <section className="pt-16 pb-16" style={{ backgroundColor: "#F6F6F6" }}>
        <div className="container mx-auto px-6">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                Top 100 websites
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>
          <div className="container mx-auto px-6">
            <section className="mt-4 grid grid-cols-1 gap-8 lg:grid-cols-2 xl:mt-12">
              <div className="rounded-lg  p-1 overflow-hidden">
                <ButtonGroup style={{ display: "flex", margin: 10 }}>
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      name="radio"
                      value={radio.value}
                      checked={radioValue === radio.value}
                      onChange={(e) => changeTable(radio.value, e)}
                      className="flex justify-center align-middle"
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                <div
                  className="overflow-scroll"
                  hidden={false}
                  style={{ height: "800px" }}
                  id="divIntruders"
                >
                  <MyComponent myObject={intruders} />
                </div>
                <div
                  className="h-96 overflow-scroll"
                  hidden={true}
                  id="divTrackers"
                  style={{ height: "800px" }}
                >
                  <MyComponent myObject={trackers} />
                </div>
                <div
                  className="h-96 overflow-scroll"
                  hidden={true}
                  id="divPopular"
                  style={{ height: "800px" }}
                >
                  <MyComponent myObject={popular} />
                </div>
                <div
                  className="h-96 overflow-scroll"
                  hidden={true}
                  id="divThirdParties"
                  style={{ height: "800px" }}
                >
                  <MyComponent myObject={thirdparty} />
                </div>
              </div>
              <div className="rounded-lg p-8">
                <div className="mx-2">
                  <section id="DomainInfo">
                    <Table
                      striped
                      bordered
                      hover
                      className="border overflow-hidden"
                      style={{ style: "table-layout: fixed; width: 50px" }}
                    >
                      <tbody className="overflow-hidden">
                        <tr style={{ textAlign: "center" }}>
                          <th colSpan="2">Domain info</th>
                        </tr>
                        <tr className="border">
                          <th style={{ width: "35%" }} className="border">
                            Domain name
                          </th>
                          <td
                            style={{ width: "65%" }}
                            className="border text-center"
                          >
                            {domainName}
                          </td>
                        </tr>
                        <tr>
                          <th className="border">Elements</th>
                          <td className="border text-center">
                            {domainElements}
                          </td>
                        </tr>
                        <tr>
                          <th className="border">Intrusion level</th>
                          <td className="border text-center">
                            {domainIntrusion}%
                          </td>
                        </tr>
                        <tr>
                          <th className="border">First-Party elements</th>
                          <td className="border text-center">{domainFP}</td>
                        </tr>
                        <tr>
                          <th className="border">Third-Party elements</th>
                          <td className="border text-center">{domainTP}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className="rounded-lg pt-8 pb-8">
                      <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl text-center">
                        Website tracking
                      </h1>
                      <div
                        className="d-flex justify-center"
                        style={{ height: "500px" }}
                      >
                        <Radar data={dataRadar} />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
