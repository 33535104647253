import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { Link } from "react-router-dom";

const navbar = () => {
  const barraNavegacion = {
    display: "flex",
    borderColor: "rgba(0, 0, 0, 0.08)",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgb(59 130 246)",
    height: "auto",
    padding: "10px 0px 0px 0px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    textDecoration: "none"
  };

  return (
    <Navbar expand="lg" style={barraNavegacion}>
      <div className="container mx-auto px-6">
        <Navbar.Brand>
          <Link to="/" style={{textDecoration: "none"}}>
            <p
              style={{
                color: "white",
                fontFamily: "Raleway",
                fontSize: 16,
                fontWeight: "800",
              }}
            >
              ePrivo
            </p>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link to={{ pathname: "/search", query: "google.com" }} style={{textDecoration: "none"}}>
              <p
                style={{
                  color: "white",
                  fontFamily: "Raleway",
                  fontSize: 16,
                  fontWeight: "bold",
                  borderBottomColor: "black"
                }}
                className="mr-6"
              >
                Search
              </p>
            </Link>
            <Link to="/historytrackers" style={{textDecoration: "none"}}>
              <p
                style={{
                  color: "white",
                  fontFamily: "Raleway",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                className="mr-6"
              >
                History trackers
              </p>{" "}
            </Link>
            <Link to="/research" style={{textDecoration: "none"}}>
              <p
                style={{
                  color: "white",
                  fontFamily: "Raleway",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                className="mr-6"
              >
                Research
              </p>{" "}
            </Link>
            <Link to="/abouteprivo" style={{textDecoration: "none"}}>
              <p
                style={{
                  color: "white",
                  fontFamily: "Raleway",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
                className="mr-6"
              >
                About
              </p>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default navbar;
