import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <section className="bg-white">
        <div className="container mx-auto px-6">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                What is web tracking?
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>
          <section className="mt-4 gap-8 xl:mt-12">
            <div className="rounded-lg border p-8">
              <p className="leading-loose text-justify text-gray-500">
                Web tracking is the practice of identifying users through
                multiple browsing sessions in order to compile large amounts of
                personal data about them. In a simpler way, it is how websites
                study our behavior when we visit them. It is a very common
                practice with more than 90% of websites containing tracking mechanisms,
                so it is something that users are involved in every time they navigate
                the internet. Thanks to tracking, users receive more relevant
                advertisements, but the majority of them don’t actually know the
                degree to which they are being tracked. The collected data can be
                shared with third parties, and is susceptible to be used for many other
                purposes.
              </p>
            </div>
          </section>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto px-6 pt-4">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                Why do websites track us?
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>
          <section className="mt-4 gap-8 xl:mt-12">
            <div className="rounded-lg border p-8">
              <p className="leading-loose text-justify text-gray-500">
                The premise of tracking is to know more about the user that
                navigates your website. The more you know, the more 
                you are going to understand the user. One of the most common ways
                to use the collected information is to personalize the experience.
                Data is extremely valuable and companies want to learn about you as
                much as they can. For instance, when you see advertisements in a website
                about things that you mayy be really interested in, that is because you
                have been tracked and your advertisers know what you want. However, 
                personal information is collected for many other more obscure purposes,
                such as price discrimination, credit scoring or personal financial assessment.
              </p>
            </div>
          </section>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto px-6 pt-4">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                Is tracking legal?
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>

          <p className="my-6 text-gray-500">
            Actually, many countries are starting to develop policies and
            regulations to protect the privacy of citizens. Nowadays there are
            three important privacy regulations because of their economic impact
            and their volume of users.
          </p>

          <section className="mt-4 gap-8 xl:mt-12">
            <div className="rounded-lg border p-8">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800 text-left">
                  Privacy act (USA)
                </h3>
              </div>
              <p className="leading-loose text-justify text-gray-500">
                Was the first law created to protect the citizens privacy, and
                it specifies that personal data cannot be shared between
                different national agencies, except by a request or prior
                consent of the individual. It also establishes a number of
                exceptions like statistical or archival purposes, law
                enforcement purposes or congressional investigations. On January
                1st of 2020, the state of California applied a new regulation to
                enforce its citizens privacy. This law specifies that
                California’s citizens must know about the personal information a
                business collects about them, and how it is used and shared,
                also establishes their right to delete their personal
                information. But this law lets companies automatically collect
                users’ data if there is a notification of the purpose it will be
                used for, and a link on the home page of the website to opt-out
                from the company data selling processes. To completely remove
                their personal information, users have to ask for deletion of
                it, and there are some exceptions that let companies partially
                maintain it.
              </p>
            </div>

            <div className="rounded-lg border p-8 my-6">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800 text-left">
                  The General Data Protection Regulation (EU)
                </h3>
              </div>
              <p className="leading-loose text-gray-500">
                Is the European law to protect the privacy of its citizens.
                Defines that all the data collected from EU citizens and
                foreigners living in EU must comply some specific regulations,
                and delimits the approved data collection methods to six
                possible scenarios: <br/>1. The person being subject of the data
                collection gives unambiguous consent. <br/>2. Formalizing a contract
                where the subject is one of the parts. <br/>3. To comply with a legal
                obligation. <br/>4. To save human lives. <br/>5. To perform a task of
                public interest. 6. To obtain the legitimate interest of the
                user. The user has the right to request copies, rectifications
                or deletion.
              </p>
            </div>

            <div className="rounded-lg border p-8 my-6">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800">
                  The Personal Information Protection Law (CHINA)
                </h3>
              </div>
              <p className="leading-loose text-gray-500">
                Is a new privacy law that is effective since November 2021, and
                it establishes that users have the right to clearly know the
                data collected, its purpose and the method and scope of
                handling. As the EU regulation, it established different
                scenarios where data can be collected: <br/>1. Obtaining individual’s
                consent under full knowledge, and in a voluntary and explicit
                way. <br/>2. Formalizing a contract where the subject is one of the
                parts or included in one of the parts. <br/>3. Where necessary to
                fulfill legal duties and responsibilities or legal obligations.
                <br/>4. To protect public health, personal lives or security of their
                property. <br/>5. To implement news reporting, public opinion
                supervision, and other such activities for the public interest
                within reasonable scope. <br/>6. When handling personal information
                disclosed by persons themselves or otherwise already lawfully
                disclosed. <br/>7. Other circumstances provided in laws and
                administrative regulations.
              </p>
            </div>
          </section>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto px-6 pt-4">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                First-party vs third-party tracking
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>

          <section className="mt-4 grid grid-cols-1 gap-8 lg:grid-cols-2 xl:mt-12">
            <div className="rounded-lg border p-8">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800 text-center">
                  First-party tracking
                </h3>
              </div>
              <p className="leading-loose text-justify text-gray-500">
                First party tracking occurs when the website that you are
                visiting is tracking you and your data, usually to personalize the 
                experience. Thus, this kind of tracking is not a threat for the user
                and is seen as an agreement between the user and the site with
                the purpose of getting a better experience.{" "}
              </p>
            </div>
            <div className="rounded-lg border p-8">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800 text-center">
                  Third-party tracking
                </h3>
              </div>
              <p className="leading-loose text-gray-500 text-justify">
                Third party tracking are tracking systems running from third-party
                services embedded in the main website. When a service is popular
                enough to be present in many important websites, the underlying service
                obtains a high volume of data that can be used to infer
                sensible information from the user. Third party tracking is considered
                a serious privacy threat, and many browsers include some kind of
                blocking measures. Unfortunately, such measures are commonly insufficient,
                as some of the identification methods are very complex to detect.
              </p>
            </div>
          </section>
        </div>
      </section>

      <section className="bg-white pt-4 pb-16">
        <div className="container mx-auto pt-4 px-6">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                ePrivo
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>
          <section className="mt-4 gap-8 xl:mt-12">
            <div className="rounded-lg border p-8">
              <p className="leading-loose text-justify text-gray-500">
                eprivo.eu is an electronic-privacy observatory created by the
                Universitat Politècnica de Catalunya (UPC). Its main purpose is
                to allow any person to inspect the privacy-friendliness of any
                website on the Internet. The underlying system is based on the
                Online Resource Mapper (ORM) a tool created by the UPC to
                inspect every URL loaded by the website and its corresponding
                resource. The system obtains multiple information about the HTTP
                requests as well as the actual code inside each resource being
                loaded. To discover web tracking it uses a combination of a
                modified uBlock Origin to initially label the already-known
                tracking methods and TrackSign, a novel technique depeloped by
                the Broadband Communications Center at UPC, to discover unknown
                and hidden tracking by inspecting the actual code of the
                website.
              </p>
            </div>
          </section>
        </div>
      </section>
      
      <section className="bg-white">
        <div className="container mx-auto px-6 pt-4 pb-16">
          <div className=" md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                Detection methods
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>
          <section className="mt-4 gap-8 xl:mt-12">
            <div className="rounded-lg border p-8">
              <p className="leading-loose text-justify text-gray-500">
                ePrivo detects numerous known and unknown web tracking systems
                by means of a heuristical approach. Between them some of the
                most common methods are automatically labeled by means of
                specialized modules. Right now ePrivo can automaticall label
                the next methods:
              </p>
              <section className="mt-4 grid grid-cols-1 gap-8 xl:mt-12">
                <div className="rounded-lg p-8">
                  <div className="mx-2">
                    <h3 className="font-semibold text-gray-800 text-center ">
                      Cookies
                    </h3>
                  </div>
                  <p className="leading-loose text-justify text-gray-500">
                    A cookie is a small file containing a user identifier send by the
                    web server to the browser exploring the website. The same file will
                    be sent back to the server every time the website is opened and, 
                    consequently, the server can identify the user between different
                    sessions.
                    <br />
                    <br /> <b>• Session cookies:</b> non-persistent cookies that
                    automatically deleted when the browser is closed. Is the
                    most privacy-friendly web tracking method because the
                    information collected is limited to the current session.
                    <br /> <b>• Long-living cookies:</b> Have a living period longer
                    than 3 months. It is a bad practice, because the website is
                    constantly receiving information about the user.
                    <br /> <b>• Very long-living cookies:</b> A cookie that is
                    persistent for a period over a year, it goes against some of
                    the current regulations.
                    <br /> <b>• JavaScript cookies:</b> Cookies are mainly setup or
                    obtained using JavaScript code, this not only permits to
                    recover the information already presented in the cookie, but
                    to regenerate cookies based on other information stored in
                    the computer.
                    <br /> <b>• Third-party cookies:</b> A cookie that is created by a
                    domain different of the accessed by the user.
                    <br /> <b>• Tracking cookie:</b> A long-living or very long-living
                    cookie pertaining to a third-party website. Allows
                    third-party services to track each user on every website
                    where the third-party is present, allowing them to acquire a
                    huge amount of personal information.
                  </p>
                </div>
                <div className="rounded-lg p-8">
                  <div className="mx-2">
                    <h3 className="font-semibold text-gray-800 text-center">
                      Browser fingerprinting
                    </h3>
                  </div>
                  <p className="leading-loose text-gray-500 text-justify">
                    Online fingerprinting is a tracking and identification
                    method that allow websites to associate individual browsing
                    sessions with one site visitor. It collects information
                    about the browser type and version, operating system, active
                    plugins, time zone, language, and many other. Although it
                    might seem as generic data, probabilistically there is a 
                    significantly small chance for another user to have 100% 
                    exactly matching information, becoming a user identifier.
                    <br />
                    <br /> <b>• Font fingerprinting:</b> measures the dimensions
                    of HTML elements that contain text.
                    <br /> <b>• Canvas fingerprinting:</b> relies on variations
                    in how canvas images are rendered different web browsers and
                    platforms.
                    <br /> <b>• Mouse fingerprinting:</b> tracks the mouse
                    movement of the user.
                    <br /> <b>• WebGL fingerprinting:</b> it collects
                    information about the user’s graphics card.
                  </p>
                </div>
              </section>
            </div>
          </section>
        </div>
      </section>

      <section className="px-6 pt-8" style={{ backgroundColor: "#F6F6F6" }}>
        <div className="container mx-auto ">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                Permanent staff
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>

          <section className="mt-4 grid grid-cols-1 gap-8 lg:grid-cols-2 xl:mt-12">
            <div className="rounded-lg border p-8">
              <div className="mx-2">
                <h2 className="font-semibold text-gray-800 text-center">
                  Ismael Castell Uroz
                </h2>
                <h5 className="font-light text-gray-800 text-center">
                  main researcher, main developer, data scientist
                </h5>
              </div>
            </div>
            <div className="rounded-lg border p-8">
              <div className="mx-2">
                <h2 className="font-semibold text-gray-800 text-center">
                  Pere Barlet Ros
                </h2>
                <h5 className="font-light text-gray-800 text-center">
                  main researcher
                </h5>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="pb-16" style={{ backgroundColor: "#F6F6F6" }}>
        <div className="container mx-auto px-6 pt-4">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                Collaborators
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>
          <section className="mt-4 gap-8 xl:mt-12 flex flex-wrap justify-center">
            <div className="rounded-lg border p-8 w-96">
              <h3 className="font-normal text-gray-800 text-center">
                Marcel Urpí Bricollé
              </h3>
              <h5 className="font-light text-gray-800 text-center">
                support researcher
              </h5>
            </div>
            <div className="rounded-lg border p-8 w-96">
              <h3 className="font-normal text-gray-800 text-center">
                Ismael Douha Prieto
              </h3>
              <h5 className="font-light text-gray-800 text-center">
                support researcher
              </h5>
            </div>
            <div className="rounded-lg border p-8 w-96">
              <h3 className="font-normal text-gray-800 text-center">
                Mertixell Basart Dotrás
              </h3>
              <h5 className="font-light text-gray-800 text-center">
                support researcher
              </h5>
            </div>
            <div className="rounded-lg border p-8 w-96">
              <h3 className="font-normal text-gray-800 text-center">
                Carlos Balaguer Moreno
              </h3>
              <h5 className="font-light text-gray-800 text-center">
                frontend developer
              </h5>
            </div>
            <div className="rounded-lg border p-8 w-96">
              <h3 className="font-normal text-gray-800 text-center">
                Pol Messegué Molina
              </h3>
              <h5 className="font-light text-gray-800 text-center">
                backend administrator
              </h5>
            </div>
            <div className="rounded-lg border p-8 w-96">
              <h3 className="font-normal text-gray-800 text-center">
                Aleix Velasco Calvo
              </h3>
              <h5 className="font-light text-gray-800 text-center">
                security administrator
              </h5>
            </div>
          </section>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AboutUs;
