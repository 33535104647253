import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const labelsPie = ["Tracked", "Non-tracked"];


export const dataPie = {
  labels: ["Tracked", "Non-tracked"],
  datasets: [
    {
      label: "% of websites",
      data: [percentageTracked, 100 - percentageTracked],
      backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1,
    },
  ],
};

var websites = [];

var nontracked;
var tracked;
var percentageTracked;

const HistoryTrackers = () => {
  const [dataTable, setDataTable] = useState(null);

  const [dataPie, setDataPie] = useState({
    labels: ["Tracked", "Non-tracked"],
    datasets: [
      {
        label: "% of websites",
        data: [percentageTracked, 100 - percentageTracked],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ]
});

  async function chargeData() {
    axios
      .get("https://eprivo.eu/api/api.php?history")
      .then((res) => {
        console.log("History", res.data);
        websites = res.data;
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get("https://eprivo.eu/api/api.php?history_presence")
      .then((res) => {
        nontracked = parseInt(res.data.non_tracked);
        tracked = parseInt(res.data.tracked);
        percentageTracked = (tracked / (tracked + nontracked)).toFixed(4) * 100;
        console.log("a");
        setDataTable();
        setDataPie({
          ...dataPie,
          datasets: [
            {
              ...dataPie,
              labelsPie,
              data: [percentageTracked, 100 - percentageTracked],
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
              ],
              borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
              borderWidth: 1,
            },
          ],
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    chargeData();
  }, []);

  const MyComponent = ({ myObject }) => {
    return (
      <div>
        <Table
          striped
          bordered
          hover
          className="border"
          style={{ tableLayout: "fixed", width: "100%" }}
        >
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th style={{ width: "10%" }}>Rank</th>
              <th>Domain</th>
              <th style={{ width: "15%" }}>Presence</th>
              <th style={{ width: "15%" }}>Tracked</th>
              <th style={{ width: "15%" }}>Percentage of tracking</th>
            </tr>
          </thead>
          <tbody className="overflow-scroll overflow-y-scroll">
            {Object.values(myObject).map((array, index) => (
              <TableRow key={array.id} data={array} index={index} />
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  function TableRow({ data, index }) {
    var percentageTracking = (data.tracked / data.presency).toFixed(2);
    percentageTracking = (percentageTracking * 100).toFixed(0);
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{data.name}</td>
        <td>{data.presency}</td>
        <td>{data.tracked}</td>
        <td>{percentageTracking}%</td>
      </tr>
    );
  }

  return (
    <div id="divA">
      <Navbar />
      <section className="bg-white">
        <div className="container mx-auto px-6 pb-16">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                What are history trackers?
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>
          <section className="mt-4 gap-8 xl:mt-12">
            <div className="rounded-lg border p-8">
              <p className="leading-loose text-justify text-gray-500">
                The list of websites browsed by any user can be used as another
                web tracking method. Recent studies reveal that a high
                percentage of users can be accurately identified by inspecting
                only which websites out of 100 predefined ones are visited.
                However, the online browsing history is a powerful tool to also
                discover personal information not usually available through
                other methods. For instance, by looking at the blogs or online
                magazines a user visits, companies can collect information about
                the hobbies and interests of the user. Articles read on online
                newspapers can reveal political ideologies. Cambridge Analytica,
                a company focused on data mining for electoral purposes, used
                this kind of information to collect psychological profiles of
                millions of users and influence the results of the USA president
                election as well as the withdrawal of the United Kingdom from
                the European Union.
                <br />
                <br /> The most common way of obtaining users’ browsing
                histories is through a third-party service embedded in multiple
                domains, such as embedded video services, online maps or
                marketing and analytics engines. However, the best example of
                third-party trackers are the social networks. Many websites
                include interaction buttons from the most popular social
                networks in order to engage their users and attract them. Social
                networks can obtain information about the original URL accessed
                every time a website includes or loads one of their buttons.
                <br />
                <br />
              </p>
              <div className="rounded-lg lg:p-8 h-4/5">
                <div className="d-flex justify-center h-72 mt-4">
                  <Pie data={dataPie} />
                </div>
                <h6 className="font-light text-gray-800 text-center lg:mt-4 lg:gap-8 lg:p-8 ">
                  Percentage of tracking of history trackers in websites where
                  are present
                </h6>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="pt-8 pb-16" style={{ backgroundColor: "#F6F6F6" }}>
        <div className="container mx-auto px-6">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                Top 100 websites history trackers
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>
          <div className="container mx-auto px-6">
            <section className="mt-4 grid grid-cols-1 gap-8 xl:mt-12">
              <div className="rounded-lg  p-1 overflow-hidden">
                <div className="overflow-scroll" style={{ height: "800px" }}>
                  <MyComponent myObject={websites} />
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default HistoryTrackers;
