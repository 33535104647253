import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { ForceGraph3D } from "react-force-graph";
import Table from "react-bootstrap/Table";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { Chart, ArcElement } from "chart.js";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Col } from "react-bootstrap";
import {
  Geography,
  ZoomableGroup,
  ComposableMap,
  Geographies,
} from "react-simple-maps";
import "./legend.css";
import { countries } from "country-data";
import Prism from "prismjs";
import "./vscode.css";

Chart.register(ArcElement);

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const radios = [
  { name: "Tracking", value: "1" },
  { name: "Third-Party", value: "2" },
  { name: "Type", value: "3" },
];

var codeSnippets = [];
var codeSnippets2 = [];

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

var dataContent;

//DOMAIN INFO
var idDomain = "domain...";
var elementsDomain;
var numFirstParty;
var numThirdParty;
var intrusionLevel = null;

//DOMAIN ELEMENTS
var session_cookies;
var long_living_cookies;
var very_long_living_cookies;
var javascript_cookies;
var third_party_cookies;
var tracking_cookies;
var font_fingerprinting;
var canvas_fingerprinting;
var canvas_fingerprinting_big;
var mouse_fingerprinting;
var webgl_fingerprinting;
var other_tracking;

export const optionsRadar = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "center",
    },
  },
};

const labels = [
  "Session cookies",
  "Long living cookies",
  "Very long living cookies",
  "Javascript cookies",
  "Third party cookies",
  "Tracking cookies",
  "Font fingerprinting",
  "Canvas fingerprinting",
  "Canvas fingerprinting big",
  "Mouse fingerprinting",
  "Webgl fingerprinting",
  "Other tracking",
];

export const dataTracking = {
  labels,
  datasets: [
    {
      label: "# of elements",
      data: [
        session_cookies,
        long_living_cookies,
        very_long_living_cookies,
        javascript_cookies,
        third_party_cookies,
        tracking_cookies,
        font_fingerprinting,
        canvas_fingerprinting,
        canvas_fingerprinting_big,
        mouse_fingerprinting,
        webgl_fingerprinting,
        other_tracking,
      ],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

export const dataPie = {
  labels: ["First-Party elements", "Third-Party elements"],
  datasets: [
    {
      label: "# of elements",
      data: [numFirstParty, numThirdParty],
      backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
      borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
      borderWidth: 1,
    },
  ],
};

var tracking = true;
var thirdParty = false;
var type = false;

const Search = () => {
  var arrayCountries = [];

  const [mapData, setMapData] = useState(arrayCountries);

  const isoCountries = require("i18n-iso-countries");

  function alpha3ToCountry(alpha3Code) {
    const country = countries[alpha3Code];
    return country ? country.name : "Unknown Country";
  }

  function changeHover(d) {
    if (d){
      var countryName = alpha3ToCountry(d.country);
      var string = countryName + " - " + d.quantity;
      document.getElementById("tooltipContent").innerHTML = string;
    }
  }

  function cleanHover() {
    document.getElementById("tooltipContent").innerHTML = " ";
  }

  const MapChart = () => (
    <div style={{ position: "relative" }}>
      <ComposableMap>
        <ZoomableGroup>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                if (mapData != undefined)
                  var d = mapData.find((s) => s.country === geo.id);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    //fill={d ? "#ed1818" : "#cccccc"}
                    fill={
                      d
                        ? d.quantity > 5
                          ? d.quantity > 10
                            ? d.quantity > 20
                              ? d.quantity > 30
                                ? d.quantity > 40
                                  ? d.quantity > 50
                                    ? "#ff1818"
                                    : "#ee1818"
                                  : "#dd1818"
                                : "#cc1818"
                              : "#bb1818"
                            : "#aa1818"
                          : "#991818"
                        : "#cccccc"
                    }
                    onMouseEnter={() => {
                      changeHover(d);
                    }}
                    onMouseLeave={() => {
                      cleanHover();
                    }}
                    style={{
                      hover: {
                        //fill: "#e74c3c",
                        outline: "none",
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <div
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          backgroundColor: "#FFF",
          padding: 10,
          border: "1px solid #ccc",
          borderRadius: 5,
        }}
      >
        <p id="tooltipContent"></p>
      </div>
    </div>
  );

  const Snippet = ({ code }) => {
    const formattedCode = Prism.highlight(
      code,
      Prism.languages.javascript,
      "javascript"
    );
    return (
      <pre className="snippet">
        <code
          className="language-javascript"
          dangerouslySetInnerHTML={{ __html: formattedCode }}
        />
      </pre>
    );
  };

  const SnippetList = ({ snippets }) => {
    return (
      <div className="snippet-list">
        {snippets.map((snippet, index) => (
          <React.Fragment key={index}>
            <Snippet code={snippet.code} />
            {index !== snippets.length - 1 && (
              <hr style={{ borderTop: "2px solid grey" }} />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  const FileCode = ({ snippets2 }) => {
    return (
      <div className="snippet-list">
        <Snippet code={snippets2}/>
      </div>
    );
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState(searchParams.get("query"));

  const [radioValue, setRadioValue] = useState("1");

  const [,newCodeSnippets] = useState(0);
  
  const [,newCodeSnippets2] = useState(0);

  function updateCodeSnippets2(newCode) {
    newCodeSnippets2(newCode);
  }

  function updateCodeSnippets(newCode) {
    newCodeSnippets(newCode);
  }

  function updateName(
    name,
    type,
    https,
    ip,
    resource_id,
    urlCountryCode,
    tracking,
    third_party,
    resourceIsTracking,
    resourceSize,
    resourceType
  ) {
    document.getElementById("DomainInfo").hidden = true;
    document.getElementById("NodeInfo").hidden = false;
    document.getElementById("nodeNamee").innerHTML = name;
    document.getElementById("nodeType").innerHTML = type;
    if (tracking != null || resourceIsTracking == 1) tracking = 1;
    if (https == null) document.getElementById("nodeHTTPS").innerHTML = "No";
    else document.getElementById("nodeHTTPS").innerHTML = "Yes";
    if (ip != null) document.getElementById("nodeIP").innerHTML = ip;
    else document.getElementById("nodeIP").innerHTML = "-";
    if (urlCountryCode != null) {
      urlCountryCode = alpha3ToCountry(urlCountryCode);
      document.getElementById("urlCountryCode").innerHTML = urlCountryCode;
    } else document.getElementById("urlCountryCode").innerHTML = "-";
    if (tracking != null)
      document.getElementById("nodeTracking").innerHTML = "Yes";
    else document.getElementById("nodeTracking").innerHTML = "No";
    if (third_party == 1)
      document.getElementById("nodeThirdParty").innerHTML = "Yes";
    else document.getElementById("nodeThirdParty").innerHTML = "No";
    if (resource_id != null && resourceType != null) {
      document.getElementById("ResourceInfo").hidden = false;
      document.getElementById("resourceType").innerHTML = resourceType;
      if (resourceIsTracking == "1" || tracking == 1) {
        document.getElementById("resourceTracking").innerHTML = "Yes";
        axios
          .get(
            "https://eprivo.eu/api/api.php?resource_tracking&resource_id=" +
              resource_id
          )
          .then((res) => {
            if (!res.data){
              document.getElementById("divCodeSnippets").hidden = true;
              document.getElementById("divFileCode").hidden = true;
            } else if (!res.data.message && !res.data.file) {
              codeSnippets = res.data;
              updateCodeSnippets(codeSnippets);
              document.getElementById("divFileCode").hidden = true;
              document.getElementById("divCodeSnippets").hidden = false;
            } else if (res.data.file) {
              codeSnippets2 = res.data.file.code;
              if(codeSnippets2 != "") {
                document.getElementById("divCodeSnippets").hidden = true;
                document.getElementById("divFileCode").hidden = false;
                updateCodeSnippets2(codeSnippets2);
              } else {
                document.getElementById("divCodeSnippets").hidden = true;
                document.getElementById("divFileCode").hidden = true;
              }
            }
            else {
              document.getElementById("divCodeSnippets").hidden = true;
              document.getElementById("divFileCode").hidden = true;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else document.getElementById("resourceTracking").innerHTML = "No";
      document.getElementById("resourceSize").innerHTML =
        resourceSize + " bytes";
    } else {
      document.getElementById("ResourceInfo").hidden = true;
      document.getElementById("divCodeSnippets").hidden = true;
    }
  }

  function showDomain() {
    document.getElementById("divCodeSnippets").hidden = true;
    document.getElementById("DomainInfo").hidden = false;
    document.getElementById("NodeInfo").hidden = true;
    document.getElementById("ResourceInfo").hidden = true;
  }

  const backgroundWhite = {
    backgroundColor: "white",
  };

  const handleChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    setSearchParams({
      query: newQuery,
    });
  };

  function changeGraph(value, e) {
    setRadioValue(e.currentTarget.value);
    if (value == 1) {
      tracking = true;
      thirdParty = false;
      type = false;
      document.getElementById("isTrackingDiv").hidden = false;
      document.getElementById("isThirdPartyDiv").hidden = true;
      document.getElementById("typeDiv").hidden = true;
    } else if (value == 2) {
      tracking = false;
      thirdParty = true;
      type = false;
      document.getElementById("isTrackingDiv").hidden = true;
      document.getElementById("isThirdPartyDiv").hidden = false;
      document.getElementById("typeDiv").hidden = true;
    } else if (value == 3) {
      tracking = false;
      thirdParty = false;
      type = true;
      document.getElementById("isTrackingDiv").hidden = true;
      document.getElementById("isThirdPartyDiv").hidden = true;
      document.getElementById("typeDiv").hidden = false;
    }
    handle3DNewGraph();
  }

  function selectColor(node) {
    if (tracking === true) {
      if (
        node.trackingName !== null ||
        (node.resourceIsTracking != null && node.resourceIsTracking != "0")
      )
        return "rgb(255, 60, 60)";
      else return "rgb(80, 185, 255)";
    } else if (thirdParty === true) {
      if (node.is_third === "1") return "rgb(255, 60, 60)";
      else return "rgb(80, 185, 255)";
    } else if (type === true) {
      if (node.type === "main_frame") return "rgb(31, 119, 180)";
      else if (node.type === "image") return "rgb(174, 199, 232)";
      else if (node.type === "stylesheet") return "rgb(255, 127, 14)";
      else if (node.type === "beacon") return "rgb(255, 187, 120)";
      else if (node.type === "script") return "rgb(44, 160, 44)";
      else if (node.type === "font") return "rgb(152, 223, 138)";
      else if (node.type === "xmlhttprequest") return "rgb(214, 39, 40)";
      else if (node.type === "websocket") return "rgb(255, 152, 150)";
      else if (node.type === "sub_frame") return "rgb(148, 103, 189)";
      else if (node.type === "object") return "rgb(197, 176, 213)";
      else if (node.type === "media") return "rgb(140, 86, 75)";
      else if (node.type === "other") return "rgb(196, 156, 148)";
      else if (node.type === "csp_report") return "rgb(227, 119, 194)";
    }
  }
  const refGraph = useRef(null);
  const refInfo = useRef(null);
  const [widthGraph, setWidthGraph] = useState(0);
  const [, setWidthInfo] = useState(0);

  useLayoutEffect(() => {
    setWidthGraph(refGraph.current.offsetWidth);
    setWidthInfo(refInfo.current.offsetWidth);
  }, []);

  const [dataPie, dataPieNew] = useState({
    labels: ["First-Party elements", "Third-Party elements"],
    datasets: [
      {
        label: "# of elements",
        data: [0, 0],
        backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  });

  const [dataRadar, dataRadarNew] = useState({
    labels,
    datasets: [
      {
        label: "# of elements",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });

  const [, new3DGraph] = useState({
    height: 600,
    width: widthGraph,
    graphData: dataContent,
    nodeColor: (node) => selectColor(node),
    backgroundColor: "white",
    linkColor: () => "black",
    linkWidth: "200",
    linkOpacity: 0.4,
    nodeOpacity: 1,
    onNodeClick: (node) =>
      updateName(
        node.name,
        node.type,
        node.state,
        node.ip,
        node.resourceId,
        node.countryCode,
        node.trackingName,
        node.is_third,
        node.resourceIsTracking,
        node.resourceSize,
        node.resourceType
      ),
    onBackgroundClick: () => showDomain(),
    nodeLabel: "",
  });

  function handleNewPie() {
    dataPieNew({
      ...dataPie,
      datasets: [
        {
          label: "# of elements",
          data: [numFirstParty, numThirdParty],
          backgroundColor: [
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 99, 132, 0.2)",
          ],
          borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
          borderWidth: 1,
        },
      ],
    });
  }

  function handleNewRadar() {
    dataRadarNew({
      ...dataRadar,
      datasets: [
        {
          label: "# of elements",
          data: [
            session_cookies,
            long_living_cookies,
            very_long_living_cookies,
            javascript_cookies,
            third_party_cookies,
            tracking_cookies,
            font_fingerprinting,
            canvas_fingerprinting,
            canvas_fingerprinting_big,
            mouse_fingerprinting,
            webgl_fingerprinting,
            other_tracking,
          ],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    });
  }

  function handle3DNewGraph() {
    new3DGraph({
      height: 600,
      width: widthGraph,
      graphData: dataContent,
      nodeColor: (node) => selectColor(node),
      backgroundColor: "white",
      linkColor: () => "black",
      linkWidth: "200",
      linkOpacity: 0.4,
      nodeOpacity: 1,
      onNodeClick: (node) =>
        updateName(
          node.name,
          node.type,
          node.state,
          node.ip,
          node.resourceId,
          node.countryCode,
          node.trackingName,
          node.is_third,
          node.resourceIsTracking,
          node.resourceSize,
          node.resourceType
        ),
      onBackgroundClick: (node) => showDomain(),
      nodeLabel: "",
    });
  }

  function getDomainId(name) {
    if (!name) {
      getDomainInfo(0);
    } else {
      getDomainInfo(0);
      axios
        .get(
          "https://eprivo.eu/api/api.php?domain_info&domain_name=" + name /*params*/
        )
        .then((res) => {
          if (res.data.message === "No elements") {
            // Domain not in database;
            axios
              .get(
                "https://eprivo.eu/api/api.php?new_domain&domain_name=" + name /*params*/
              )
              .then((res) => {
                if (res.data.message === "Domain enqueued") {
                  document.getElementById("msg").innerHTML = "The domain " + name + " is not in our database.<br>Queued into the system.<br>Try again in a few minutes.";
                } else {
                  document.getElementById("msg").textContent = "Invalid domain";
                }
              })
              .catch((err) => {
                console.error(err);
              });
          } else if (res.data[0].priority === "1" || res.data[0].enqueued === "1") {
            document.getElementById("msg").innerHTML = "The domain " + name + " is already enqueued into the system.<br>Try again in a few minutes.";
          } else {
            if (res.data[0].id !== undefined || res.data[0].id !== "")
              getDomainInfo(res.data[0].id);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  const updateMapa = () => {
    dataContent.nodes.forEach((element) => {
      if (element.countryCode != null) {
        var code = alpha2ToAlpha3(element.countryCode);
        if (arrayCountries.find((e) => e.country == code) == undefined) {
          arrayCountries.push({ country: code, quantity: 1 });
        } else {
          var e = arrayCountries.find((e) => e.country == code);
          ++e.quantity;
        }
      }
    });
    setMapData(arrayCountries);
  };

  function getDomainInfo(id) {
    if (id === 0){
      idDomain = "";
      elementsDomain = 0;

      //Pie chart
      numFirstParty = 0;
      numThirdParty = 0;

      //Radar chart
      session_cookies = undefined;
      long_living_cookies = undefined;
      very_long_living_cookies = undefined;
      javascript_cookies = undefined;
      third_party_cookies = undefined;
      tracking_cookies = undefined;
      font_fingerprinting = undefined;
      canvas_fingerprinting = undefined;
      canvas_fingerprinting_big = undefined;
      mouse_fingerprinting = undefined;
      webgl_fingerprinting = undefined;
      other_tracking = undefined;
      intrusionLevel = 0;
      handleNewPie();
      handleNewRadar();

      dataContent = {nodes: [], links: []};
      arrayCountries.length = 0;
      updateMapa(dataContent);
      handle3DNewGraph();
    } else {
      axios
        .get("https://eprivo.eu/api/api.php?domain_info&domain_id=" + id /*params*/)
        .then((res) => {
          if (res.data.message === "No elements") {
            document.getElementById("msg").textContent = "Invalid domain";
          } else {
            idDomain = res.data[0].name;
            document.getElementById("msg").textContent = "Intrusion level of " + idDomain;

            elementsDomain = res.data[0][0].elements;

            //Pie chart
            numFirstParty = res.data[0][0].elements - res.data[0][0].third_parties;
            numThirdParty = res.data[0][0].third_parties;

            //Radar chart
            session_cookies = res.data[0][0].session_cookies;
            long_living_cookies = res.data[0][0].long_living_cookies;
            very_long_living_cookies = res.data[0][0].very_long_living_cookies;
            javascript_cookies = res.data[0][0].javascript_cookies;
            third_party_cookies = res.data[0][0].third_party_cookies;
            tracking_cookies = res.data[0][0].tracking_cookies;
            font_fingerprinting = res.data[0][0].font_fingerprinting;
            canvas_fingerprinting = res.data[0][0].canvas_fingerprinting;
            canvas_fingerprinting_big = res.data[0][0].canvas_fingerprinting_big;
            mouse_fingerprinting = res.data[0][0].mouse_fingerprinting;
            webgl_fingerprinting = res.data[0][0].webgl_fingerprinting;
            other_tracking = res.data[0][0].other_tracking;
            intrusionLevel = res.data[0][0].intrusion_level;

            //Chart update
            handleNewPie();
            handleNewRadar();

            axios
              .get("https://eprivo.eu/api/api.php?nodeslinks&domain_id=" + id)
              .then((res) => {
                dataContent = res.data;
                arrayCountries.length = 0;
                updateMapa(dataContent);
                handle3DNewGraph();
                showDomain();
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  const clickDomain = () => {
    if (query != undefined || query != null || query != "" || query != " ")
      getDomainId(query);
  };

  useEffect(() => {
    if (query != undefined || query != null || query != "" || query != " ")
      getDomainId(query);
  }, []);

  function alpha2ToAlpha3(alpha2Code) {
    const alpha3Code = isoCountries.alpha2ToAlpha3(alpha2Code);
    return alpha3Code;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    clickDomain();
  };

  return (
    <div>
      <Navbar />

      <div style={backgroundWhite}>
        <div className="container mx-auto px-6">
          <section className="bg-white mt-2 grid grid-cols-1 gap-8 lg:grid-cols-1 xl:mt-12 rounded-lg">
            <div className="rounded-lg p-8 h-4/5">
              <h2 style={{ textAlign: "center" }}>Search for a domain:</h2>
              <table className="mt-4" width={"100%"}>
                <tbody>
                  <tr>
                    <td align="right">
                      <form onSubmit={handleSubmit}>
                        <input
                          className="border"
                          value={query}
                          placeholder="Introduce a domain..."
                          onChange={handleChange}
                          width={"1000"}
                          style={{
                            width: "100%",
                            maxWidth: "500px",
                            padding: "10px",
                            border: "none",
                            borderRadius: "10px",
                            marginRight: "10px",
                            font_size: "18px",
                          }}
                        />
                        <input type="submit" hidden onSubmit={handleSubmit} />
                      </form>
                    </td>
                    <td align="left">
                      <button
                        onClick={clickDomain}
                        style={{
                          backgroundColor: "#007aff",
                          color: "white",
                          padding: "10px 20px",
                          border: "none",
                          borderRadius: "10px",
                          fontSize: "18px",
                          cursor: "pointer",
                        }}
                      >
                        Search
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="justify-center"></div>
            </div>
          </section>
        </div>
      </div>

      <div style={backgroundWhite}>
        <div className="container mx-auto px-6">
          <section className="bg-white mt-4 grid grid-cols-1 gap-8 lg:grid-cols-1 xl:mt-12 border rounded-lg">
            <div className="rounded-lg p-8 h-4/5">
              <h1
                id="msg"
                className="text-3xl font-semibold text-gray-800 lg:text-4xl text-center"
              >
                Insert a domain to search for
              </h1>
              <div className="d-flex justify-center mt-10">
                <Col>
                  <ProgressBar
                    variant="danger"
                    now={intrusionLevel}
                    label={`${intrusionLevel}%`}
                    defaultValue={0}
                  />
                </Col>
              </div>
            </div>
          </section>
          <section className="bg-white mt-4 grid grid-cols-1 gap-8 lg:grid-cols-2 xl:mt-12 border rounded-lg">
            <div className="rounded-lg p-8 h-4/5">
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl text-center">
                First-Party vs Third-Party
              </h1>
              <div className="d-flex justify-center h-96 mt-10">
                <Pie data={dataPie} />
              </div>
            </div>
            <div className="rounded-lg pt-8 pb-8">
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl text-center">
                Website tracking
              </h1>
              <div
                className="d-flex justify-center"
                style={{ height: "500px" }}
              >
                <Radar options={optionsRadar} data={dataRadar} />
              </div>
            </div>
          </section>
        </div>
      </div>

      <div style={backgroundWhite}>
        <section className="bg-white">
          <div className="container mx-auto px-6">
            <section className="mt-4 grid grid-cols-1 gap-8 lg:grid-cols-2 xl:mt-12">
              <div
                className="rounded-lg border p-1 overflow-hidden"
                ref={refGraph}
              >
                <ButtonGroup style={{ display: "flex", margin: 10 }}>
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      name="radio"
                      value={radio.value}
                      checked={radioValue === radio.value}
                      onChange={(e) => changeGraph(radio.value, e)}
                      className="flex justify-center align-middle"
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>

                <div id="div3D">
                  <ForceGraph3D
                    height={600}
                    width={widthGraph}
                    graphData={dataContent}
                    nodeColor={(node) => selectColor(node)}
                    backgroundColor="white"
                    linkColor={() => "black"}
                    linkWidth="200"
                    linkOpacity={0.4}
                    nodeOpacity={1}
                    onNodeClick={(node) =>
                      updateName(
                        node.name,
                        node.type,
                        node.state,
                        node.ip,
                        node.resourceId,
                        node.countryCode,
                        node.trackingName,
                        node.is_third,
                        node.resourceIsTracking,
                        node.resourceSize,
                        node.resourceType
                      )
                    }
                    onBackgroundClick={(node) => showDomain()}
                    nodeLabel=""
                  />
                </div>
                <div className="md:h-20 sm:h-32">
                  <section id="isTrackingDiv" hidden={false}>
                    <div className="legend-container">
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(80, 185, 255)" }}
                        ></div>
                        <div className="legend-label">Not-Tracking</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(255, 60, 60)" }}
                        ></div>
                        <div className="legend-label">Tracking</div>
                      </div>
                    </div>
                  </section>
                  <section id="isThirdPartyDiv" hidden={true}>
                    <div className="legend-container">
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(80, 185, 255)" }}
                        ></div>
                        <div className="legend-label">First-Party</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(255, 60, 60)" }}
                        ></div>
                        <div className="legend-label">Third-Party</div>
                      </div>
                    </div>
                  </section>
                  <section id="typeDiv" hidden={true}>
                    <div className="legend-container">
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(31, 119, 180)" }}
                        ></div>
                        <div className="legend-label">Main_frame</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(174, 199, 232)" }}
                        ></div>
                        <div className="legend-label">Image</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(255, 127, 14)" }}
                        ></div>
                        <div className="legend-label">Stylesheet</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(255, 187, 120)" }}
                        ></div>
                        <div className="legend-label">Beacon</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(44, 160, 44)" }}
                        ></div>
                        <div className="legend-label">Script</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(152, 223, 138)" }}
                        ></div>
                        <div className="legend-label">Font</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(214, 39, 40)" }}
                        ></div>
                        <div className="legend-label">Xmlhttprequest</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(255, 152, 150)" }}
                        ></div>
                        <div className="legend-label">Websocket</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(148, 103, 189)" }}
                        ></div>
                        <div className="legend-label">Sub_frame</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(197, 176, 213)" }}
                        ></div>
                        <div className="legend-label">Object</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(140, 86, 75)" }}
                        ></div>
                        <div className="legend-label">Media</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(196, 156, 148)" }}
                        ></div>
                        <div className="legend-label">Other</div>
                      </div>
                      <div className="legend-item">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: "rgb(227, 119, 194)" }}
                        ></div>
                        <div className="legend-label">Csp_report</div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="rounded-lg border p-8">
                <div className="mx-2">
                  <section id="NodeInfo" hidden={true} ref={refInfo}>
                    <Table
                      striped
                      bordered
                      hover
                      className="border overflow-hidden"
                      style={{ tableLayout: "fixed", width: "100%" }}
                    >
                      <tbody className="overflow-hidden">
                        <tr style={{ textAlign: "center" }}>
                          <th colSpan="2">URL info</th>
                        </tr>
                        <tr className="border">
                          <th className="border">Url</th>
                          <td
                            className="border text-justify overflow-scroll"
                            id="nodeNamee"
                          ></td>
                        </tr>
                        <tr>
                          <th className="border">Type</th>
                          <td className="border" id="nodeType"></td>
                        </tr>
                        <tr>
                          <th className="border">Tracking</th>
                          <td className="border" id="nodeTracking"></td>
                        </tr>
                        <tr>
                          <th className="border">Third-party</th>
                          <td className="border" id="nodeThirdParty"></td>
                        </tr>
                        <tr>
                          <th className="border">HTTPS</th>
                          <td className="border" id="nodeHTTPS"></td>
                        </tr>
                        <tr>
                          <th className="border">IP Address</th>
                          <td className="border" id="nodeIP"></td>
                        </tr>
                        <tr>
                          <th className="border">Country</th>
                          <td className="border" id="urlCountryCode"></td>
                        </tr>
                      </tbody>
                    </Table>
                  </section>
                  <section id="DomainInfo">
                    <Table
                      striped
                      bordered
                      hover
                      className="border overflow-hidden"
                      style={{ style: "table-layout: fixed; width: 50px" }}
                    >
                      <tbody className="overflow-hidden">
                        <tr style={{ textAlign: "center" }}>
                          <th colSpan="2">Domain info</th>
                        </tr>
                        <tr className="border">
                          <th className="border">Domain name</th>
                          <td className="border text-justify">{idDomain}</td>
                        </tr>
                        <tr>
                          <th className="border">Elements</th>
                          <td className="border">{elementsDomain}</td>
                        </tr>
                        <tr>
                          <th className="border">Intrusion level</th>
                          <td className="border">{intrusionLevel}%</td>
                        </tr>
                        <tr>
                          <th className="border">First-Party elements</th>
                          <td className="border">{numFirstParty}</td>
                        </tr>
                        <tr>
                          <th className="border">Third-Party elements</th>
                          <td className="border">{numThirdParty}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </section>
                  <section id="ResourceInfo" hidden={true}>
                    <Table
                      striped
                      bordered
                      hover
                      className="border overflow-hidden"
                      style={{ tableLayout: "fixed", width: "100%" }}
                    >
                      <tbody className="overflow-hidden">
                        <tr style={{ textAlign: "center" }}>
                          <th colSpan="2">Resource info</th>
                        </tr>
                        <tr>
                          <th className="border">Type</th>
                          <td className="border" id="resourceType"></td>
                        </tr>
                        <tr>
                          <th className="border">Tracking</th>
                          <td className="border" id="resourceTracking"></td>
                        </tr>
                        <tr>
                          <th className="border">Size</th>
                          <td className="border" id="resourceSize"></td>
                        </tr>
                      </tbody>
                    </Table>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      <div id="divCodeSnippets" hidden={true}>
        <div className="container mx-auto px-6">
          <section className="bg-white mt-4 grid grid-cols-1 gap-8 xl:mt-12 border rounded-lg">
            <div className="rounded-lg p-8 h-4/5">
              <h3 id="snippetText" className="text-3xl font-semibold text-gray-800 lg:text-4xl text-left">
                Code parts used for tracking purposes
              </h3>
              <div className="overflow-scroll" style={{ height: "700px" }}>
                <SnippetList snippets={codeSnippets} />
              </div>
            </div>
          </section>
        </div>
      </div>

      <div id="divFileCode" hidden={true}>
        <div className="container mx-auto px-6">
          <section className="bg-white mt-4 grid grid-cols-1 gap-8 xl:mt-12 border rounded-lg">
            <div className="rounded-lg p-8 h-4/5">
              <h3 className="text-3xl font-semibold text-gray-800 lg:text-4xl text-left">
                Complete file classified as tracking
              </h3>
              <div className="overflow-scroll" style={{ height: "700px" }}>
                <FileCode snippets2={codeSnippets2} />
              </div>
            </div>
          </section>
        </div>
      </div>

      <div style={backgroundWhite}>
        <section className="bg-white">
          <div className="container mx-auto px-6 pb-16">
            <section className="bg-white mt-4 xl:mt-12 border rounded-lg">
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl text-center mt-8">
                Elements source country
              </h1>
              <div className="rounded-lg px-8">
                <MapChart />
              </div>
            </section>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Search;
