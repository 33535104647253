const Footer = () => {
  const styles = {
    backgroundColor: "rgb(39 39 39)",
    boxWidth: "xl:max-w-[1280px] w-full",
    heading2:
      "font-poppins font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full",
    paragraph:
      "font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]",

    flexCenter: "flex justify-center items-center",
    flexStart: "flex justify-center items-start",
    bottom: 0,
  };

  const margin = {
    padding: "40px",
  };

  return (
    <section style={styles}>
      <div
        className="container mx-auto px-6 py-12w-full flex justify-between items-center flex-col pt-6 border-t-[1px] border-t-[#3F3E45]"
        style={margin}
      >
        <h3 className="font-poppins font-normal text-center text-white">
          Contact
        </h3>
        <p className="font-poppins font-normal text-center text-white">
          ismael.castell@upc.edu
        </p>
        <p className="font-poppins font-normal text-center text-white">
          © Facultat d'Informàtica de Barcelona - Universitat Politècnica de
          Catalunya
        </p>
      </div>
    </section>
  );
};

export default Footer;
