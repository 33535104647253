import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Research = () => {
  return (
    <div>
      <Navbar />
      <section className="bg-white pb-16">
        <div className="container mx-auto px-6">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h1 className="text-3xl font-semibold text-gray-800 lg:text-4xl">
                Related research
              </h1>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>
          <section className="mt-4 gap-8 xl:mt-12">
            <div className="rounded-lg border p-8">
              <p className="leading-loose text-justify text-gray-500">
                ePrivo is based on a state-of-the-art web tracking detection mechanism.
                The algorithm was presented in a research paper at the INFOCOM 2021 conference.
                However, the research team has many more research publications about online
                privacy. This section presents the most important of them.
              </p>
            </div>
          </section>
        </div>
      </section>
      <section className="bg-white">
        <div className="container mx-auto px-6 pb-16">
          <div className="mt-6 md:flex md:items-center md:justify-between">
            <div>
              <h2 className=" font-semibold text-gray-800 ">
                Related publications
              </h2>
              <div className="mx-auto mt-6 flex">
                <span className="inline-block h-1 w-40 rounded-full bg-blue-500"></span>
                <span className="mx-1 inline-block h-1 w-3 rounded-full bg-blue-500"></span>
                <span className="inline-block h-1 w-1 rounded-full bg-blue-500"></span>
              </div>
            </div>
          </div>
          <section className="mt-4 gap-8 xl:mt-12">
            <div className="rounded-lg border p-8  my-6">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800 text-left">
                  ASTrack: Automatic Detection and Removal of Web Tracking Code with Minimal Functionality Loss
                </h3>
                <h5 className="font-light text-gray-800 text-left">
                  Ismael Castell-Uroz, Kensuke Fukuda and Pere Barlet-Ros
                </h5>
                <h6 className="font-light text-gray-800 text-left">
                  IEEE INFOCOM 2023 - IEEE Conference on Computer Communications
                </h6>
              </div>
              <p className="leading-loose text-justify text-gray-500 pt-2">
              Recent advances in web technologies make it more difficult than ever to detect and block web tracking systems. In this work, we propose ASTrack, a novel approach to web tracking detection and removal. ASTrack uses an abstraction of the code structure based on Abstract Syntax Trees to selectively identify web tracking functionality shared across multiple web services. This new methodology allows us to: (i) effectively detect web tracking code even when using evasion techniques (e.g., obfuscation, minification, or webpackaging); and (ii) safely remove those portions of code related to tracking purposes without affecting the legitimate functionality of the website. Our evaluation with the top 10k most popular Internet domains shows that ASTrack can detect web tracking with high precision (98%), while discovering about 50k tracking code pieces and more than 3,400 new tracking URLs not previously recognized by most popular privacy-preserving tools (e.g., uBlock Origin). Moreover, ASTrack achieved a 36% reduction in functionality loss in comparison with the filter lists, one of the safest options available. Using a novel methodology that combines computer vision and manual inspection, we estimate that full functionality is preserved in more than 97% of the websites.
              </p>
              <div className="text-right">
                <Link
                  to="https://arxiv.org/abs/2301.10895"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    onClick={""}
                    style={{
                      backgroundColor: "#007aff",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "10px",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  >
                    Access
                  </button>
                </Link>
              </div>
            </div>
            <div className="rounded-lg border p-8  my-6">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800 text-left">
                  ePrivo.eu: An Online Service for Automatic Web Tracking Discovery
                </h3>
                <h5 className="font-light text-gray-800 text-left">
                  Ismael Castell-Uroz, Ismael Douha-Prieto, Meritxell Basart-Dotrás, Pol Mesegué-Molina and Pere Barlet-Ros
                </h5>
                <h6 className="font-light text-gray-800 text-left">
                  IEEE ACCESS ( Volume: 11, March 2023 )
                </h6>
              </div>
              <p className="leading-loose text-justify text-gray-500 pt-2">
              Given the pervasiveness of web tracking practices on the Internet, many countries are developing and enforcing new privacy regulations to ensure the rights of their citizens. However, discovering websites that do not comply with those regulations is becoming very challenging, given the dynamic nature of the web or the use of obfuscation techniques. This work presents ePrivo, a new online service that can help Internet users, website owners, and regulators inspect how privacy-friendly a given website is. The system explores all the content of the website, including traffic from third parties and dynamically modified content. The ePrivo service combines different state-of-the-art tracking detection and classification methods, including TrackSign, to discover both previously known and zero-day tracking methods. After 6 months of service, ePrivo detected the largest browsing history trackers and more than 40k domains including cookies with a lifespan longer than one year, which is forbidden in some countries.
              </p>
              <div className="text-right">
                <Link
                  to="https://ieeexplore.ieee.org/document/10050035"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    onClick={""}
                    style={{
                      backgroundColor: "#007aff",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "10px",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  >
                    Access
                  </button>
                </Link>
              </div>
            </div>
            <div className="rounded-lg border p-8  my-6">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800 text-left">
                  TrackSign-labeled web tracking dataset
                </h3>
                <h5 className="font-light text-gray-800 text-left">
                  Ismael Castell-Uroz and Pere Barlet-Ros
                </h5>
                <h6 className="font-light text-gray-800 text-left">
                  Computer Networks ( Volume: 226, May 2023 )
                </h6>
              </div>
              <p className="leading-loose text-justify text-gray-500 pt-2">
                Recent studies show that more than 95% of the websites available on the Internet contain at least one of the so-called web tracking systems. These systems are specialized in identifying their users by means of a plethora of different methods. Some of them (e.g., cookies) are very well known by most Internet users. However, the percentage of websites including more "obscure" and privacy-threatening systems, such as fingerprinting methods identifying a user's computer, is constantly increasing. Detecting those methods on today's Internet is very difficult, as almost any website modifies its content dynamically and minimizes its code in order to speed up loading times. This minimization and dynamicity render the website code unreadable by humans. Thus, the research community is constantly looking for new ways to discover unknown web tracking systems running under the hood. In this paper, we present a new dataset containing tracking information for more than 76 million URLs and 45 million online resources, extracted from 1.5 million popular websites. The tracking labeling process was done using a state-of-the-art discovery web tracking algorithm called TrackSign. The dataset also contains information about online security and the relation between the domains, the loaded URLs, and the online resource behind each URL. This information can be useful for different kinds of experiments, such as locating privacy-threatening resources, identifying security threats, or determining characteristics of the URL network graph.
              </p>
              <div className="text-right">
                <Link
                  to="https://www.sciencedirect.com/science/article/pii/S1389128623001329"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    onClick={""}
                    style={{
                      backgroundColor: "#007aff",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "10px",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  >
                    Access
                  </button>
                </Link>
              </div>
            </div>
            <div className="rounded-lg border p-8  my-6">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800 text-left">
                  Demystifying Content-blockers: Measuring Their Impact on Performance and Quality of Experience
                </h3>
                <h5 className="font-light text-gray-800 text-left">
                  Ismael Castell-Uroz, Rubén Sanz-García, Josep Solé-Pareta and Pere Barlet-Ros
                </h5>
                <h6 className="font-light text-gray-800 text-left">
                  IEEE Transaction on Network and Service Management ( Volume: 19, Issue: 3, May 2022 )
                </h6>
              </div>
              <p className="leading-loose text-justify text-gray-500 pt-2">
                With the evolution of the online advertisement and tracking ecosystem, content-blockers have become the reference tool for improving the security, privacy and browsing experience when surfing the Internet. It is also commonly believed that using content-blockers to stop unsolicited content decreases the time needed for loading websites. In this work, we perform a large-scale study on the actual improvements of using content-blockers in terms of performance and quality of experience. For measuring it, we analyze the page size and loading times of the 100K most popular websites, as well as the most relevant QoE metrics, such as the Speed Index, Time to Interactive or the Cumulative Layout Shift, for the subset of the top 10K of them. Our experiments show that using content-blockers results in small improvements in terms of performance. However, contrary to popular belief, this has a negligible impact in terms of loading time and quality of experience. Moreover, in the case of small and lightweight websites, the overhead introduced by content-blockers can even result in decreased performance. Finally, we evaluate the improvement in terms of QoE based on the Mean Opinion Score (MOS) and find that two of the three studied content-blockers present an overall decrease between 3% and 5% instead of the expected improvement.
              </p>
              <div className="text-right">
                <Link
                  to="https://ieeexplore.ieee.org/document/9785859"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    onClick={""}
                    style={{
                      backgroundColor: "#007aff",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "10px",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  >
                    Access
                  </button>
                </Link>
              </div>
            </div>
            <div className="rounded-lg border p-8">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800 text-left">
                  TrackSign: Guided Web Tracking Discovery
                </h3>
                <h5 className="font-light text-gray-800 text-left">
                  Ismael Castell-Uroz, Josep Solé-Pareta and Pere Barlet-Ros
                </h5>
                <h6 className="font-light text-gray-800 text-left">
                  IEEE INFOCOM 2021 - IEEE Conference on Computer Communications
                </h6>
              </div>
              <p className="leading-loose text-justify text-gray-500 pt-2">
                Current web tracking practices pose a constant threat to the privacy of Internet users. As a result, the research community has recently proposed different tools to combat well-known tracking methods. However, the early detection of new, previously unseen tracking systems is still an open research problem. In this paper, we present TrackSign, a novel approach to discover new web tracking methods. The main idea behind TrackSign is the use of code fingerprinting to identify common pieces of code shared across multiple domains. To detect tracking fingerprints, TrackSign builds a novel 3-mode network graph that captures the relationship between fingerprints, resources and domains. We evaluated TrackSign with the top-100K most popular Internet domains, including almost 1M web resources from more than 5M HTTP requests. Our results show that our method can detect new web tracking resources with high precision (over 92%). TrackSign was able to detect 30K new trackers, more than 10K new tracking resources and 270K new tracking URLs, not yet detected by most popular blacklists. Finally, we also validate the effectiveness of TrackSign with more than 20 years of historical data from the Internet Archive.
              </p>
              <div className="text-right">
                <Link
                  to="https://ieeexplore.ieee.org/document/9488842"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    style={{
                      backgroundColor: "#007aff",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "10px",
                      fontSize: "18px",
                      cursor: "pointer",
                      display: "inline-block",
                      textAlign: "center",
                    }}
                  >
                    Access
                  </button>
                </Link>
              </div>
            </div>
            <div className="rounded-lg border p-8  my-6">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800 text-left">
                  URL-based Web Tracking Detection Using Deep Learning
                </h3>
                <h5 className="font-light text-gray-800 text-left">
                  Ismael Castell-Uroz, Théo Poissonnier, Pierre Manneback and Pere Barlet-Ros
                </h5>
                <h6 className="font-light text-gray-800 text-left">
                  2020 16th International Conference on Network and Service Management (CNSM)
                </h6>
              </div>
              <p className="leading-loose text-justify text-gray-500 pt-2">
                The pervasiveness of online web tracking poses a constant threat to the privacy of Internet users. Millions of users currently employ content-blockers in their web browsers to block tracking resources in real time. Although content-blockers are based on blacklists, which are known to be difficult to maintain and easy to evade, the research community has not succeeded in replacing them with better alternatives yet. Most of the methods recently proposed in the literature obtain good detection accuracy, but at the expense of increasing their complexity and making them more difficult to maintain and configure by the end user. In this paper, we present a new web tracking detection method, called Deep Tracking Detector (DTD), that analyzes the properties of URL strings to detect tracking resources, without using any other external features. Consequently, DTD can easily be implemented in a browser plugin and operate in real time. Our experimental results, with more than 5M HTTP requests from 100K websites, show that DTD achieves a detection accuracy higher than 97% by looking only at the URL of the resources.
              </p>
              <div className="text-right">
                <Link
                  to="https://ieeexplore.ieee.org/document/9269065"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    onClick={""}
                    style={{
                      backgroundColor: "#007aff",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "10px",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  >
                    Access
                  </button>
                </Link>
              </div>
            </div>
            <div className="rounded-lg border p-8  my-6">
              <div className="mx-2">
                <h3 className="font-semibold text-gray-800 text-left">
                  Network Measurements for Web Tracking Analysis and Detection:
                  A Tutorial
                </h3>
                <h5 className="font-light text-gray-800 text-left">
                  Ismael Castell-Uroz, Josep Solé-Pareta, Pere Barlet-Ros
                </h5>
                <h6 className="font-light text-gray-800 text-left">
                  IEEE Instrumentation & Measurement Magazine ( Volume: 23, Issue: 9, December 2020)
                </h6>
              </div>
              <p className="leading-loose text-justify text-gray-500 pt-2">
                Digital society has developed to a point where it is nearly impossible for a user to know what it is happening in the background when using the Internet. To understand it, it is necessary to perform network measurements not only at the network layer (e.g., IP, ICMP), but also at the application layer (e.g., HTTP). For example, opening a single website can trigger a cascade of requests to different servers and services to obtain the resources embedded inside it. This process is becoming so complex that, to explore only one website, the number of communications can explode easily from tens to hundreds depending on the website. Inside those communications, there is an ever-increasing portion dedicated to web tracking, a controversial practice from the security and privacy perspective.
              </p>
              <div className="text-right">
                <Link
                  to="https://ieeexplore.ieee.org/document/9289071"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    onClick={""}
                    style={{
                      backgroundColor: "#007aff",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "10px",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  >
                    Access
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Research;
